export const admin = [
  {
    _name: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['Menu']
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Master Data',
    route: '/master-data',
    icon: 'cil-file',
    items: [
      {
        name: 'List Peternak',
        to: '/master-data/list-peternak',
      },
      {
        name: 'List Kelompok',
        to: '/master-data/list-kelompok',
      },
      {
        name: 'List Jenis Hewan',
        to: '/master-data/jenis-hewan',
      },
      {
        name: 'List Bantuan',
        to: '/master-data/list-bantuan',
      },
      {
        name: 'Parameter Produksi',
        to: '/master-data/parameter-produksi'
      }
    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Populasi Hewan',
    route: '/populasi-hewan',
    icon: 'cil-file',
    items: [
      {
        name: 'List Populasi',
        to: '/populasi-hewan/list-populasi',
      },
      {
        name: 'List Produksi',
        to: '/populasi-hewan/list-produksi'
      }
    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Komunikasi',
    route: '/komunikasi',
    icon: 'cil-comment-square',

    items: [
      {
        name: 'Pesan',
        to: '/komunikasi/pesan',

      },
      {
        name: 'Online Chat',
        to: '/komunikasi/online-chat'
      },

    ]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Master User',
    route: '/master-user',
    icon: 'cil-user',
    items: [
      {
        name: 'Kelola User',
        to: '/master-user/user',
      },
      {
        name: 'Kelola Role',
        to: '/master-user/role'
      },
    ]
  },
]


export const opd = [
  {
    _name: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['Menu']
  },

  {
    _name: 'CSidebarNavItem',
    name: 'Agenda Bappeda',
    to: '/agenda',
    icon: 'cil-calendar',
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Komunikasi',
    route: '/komunikasi',
    icon: 'cil-comment-square',

    items: [
      {
        name: 'Pesan',
        to: '/komunikasi/pesan',

      },
      {
        name: 'Online Chat',
        to: '/komunikasi/online-chat'
      },

    ]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Gallery',
    to: '/gallery',
    icon: 'cil-puzzle'
  },
]

export const not_opd = [
  {
    _name: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    _name: 'CSidebarNavTitle',
    _children: ['Menu']
  },

  {
    _name: 'CSidebarNavItem',
    name: 'Agenda Bappeda',
    to: '/agenda',
    icon: 'cil-calendar',
  },
  {
    _name: 'CSidebarNavDropdown',
    name: 'Komunikasi',
    route: '/komunikasi',
    icon: 'cil-comment-square',

    items: [
      {
        name: 'Pesan',
        to: '/komunikasi/pesan',

      },
      {
        name: 'Online Chat',
        to: '/komunikasi/online-chat'
      },

    ]
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Gallery',
    to: '/gallery',
    icon: 'cil-puzzle'
  },
]